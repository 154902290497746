import React from "react";
import { ToogleContext } from "../hooks/ToogleContext";
import "../styles/cidebot.css";
import icon from "./../imgs/cideBotAvatar.webp";

function CideBot (){

    const {openChatBot, setopenChatBot} = React.useContext(ToogleContext);


    const handleOpenCidebot = () => {
        setopenChatBot(!openChatBot);
    };

    return (
        <div className="cidebot-btn" onClick={handleOpenCidebot}>
            <img className="cidebot-btn-img" src={icon}/>
        </div>
    );
}

export {CideBot};