import React from "react";
import "../styles/sectionTitle.css"

function SectionTitle ({BoldText, ligthText}){
    return (
        <div className="section-title">
            <h1> <em>{BoldText}</em>{ligthText}</h1> 
        </div>
    );
}

export {SectionTitle};