import React, {useState, useEffect} from "react";
import "./../../styles/locationFilterItem.css";

function LocationFilterItem ({regionName, cities}) {

    const [filterTabSelected, setfilterTabSelected] = useState(false);
    const [CitiesList, setCitiesList] = useState([]);
    

    const handleTabSelection = () => {
        setfilterTabSelected(true)
    };

    useEffect(() => {
        setCitiesList(cities);

        // (async () => {
        //     // const {data:{data:{GetLearningCourseModuleItems}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
        //     setCitiesList(cities);
        // })();

      }, []);
    

    return(
        <div>
            <div className="location-filter-item-main-container" onClick={handleTabSelection}>    
                <h2 className={filterTabSelected ? "location-filter-item-tab-selected" : "location-filter-item-main-tab"}>{regionName}</h2>
            </div>

            {/* {
                CitiesList.map( (City, index) => ( 
                    <LocationFilterItem
                        key = {index}
                        city = {City.name}
                    /> 
                ))
            } */}
        </div>
            );
}

export {LocationFilterItem};