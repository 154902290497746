import React from "react";
import "./../../styles/locationFilter.css";

import { LocationFilterItem } from "./LocationFilterItem";

let LocationList = [{regionName:"Centro",cities:[{name:"Bogotá"},{name:"Soacha"},{name:"Funza"}]},{regionName:"Antioquia",cities:[{name:"Medellín"},{name:"Rionegro"}]},{regionName:"Santander",cities:[{name:"Bucaramanga"},{name:"Cúcuta"}]},{regionName:"Costa",cities:[{name:"Barranquilla"},{name:"Cartagena"}]}];

function LocationFilter () {
    return(
        <div className="location-filter-main-container">
            <nav className="location-filter-nav">
                {
                    LocationList.map( (Location, index) => ( 
                        <LocationFilterItem
                            key = {index}
                            regionName = {Location.regionName}
                            cities = {Location.cities}
                        /> 
                    ))
                }
            </nav>
        </div>
    );
}

export {LocationFilter};