import React from "react";
import {useEffect} from "react";
import "../styles/scrollTop.css";

const btn_scrolltop = ()=> {
            window.scrollTo(0,0);
        };

let showButton = false;

function ScrollTop (){

    const [windowPositionY, setWindowPositionY] = React.useState(0);
    
    useEffect(() => {
        window.addEventListener('scroll', (position)=> setWindowPositionY(position.srcElement.defaultView.scrollY));

        if (windowPositionY > 300){
            showButton = true;
        } else {
            showButton = false;
        }
    
        return () => {
          window.removeEventListener('scroll', setWindowPositionY(""));
        };
      });


    return (
        <button onClick={btn_scrolltop} className={`btn-scrolltop ${showButton && "btn-scrolltop-on"}`} id="btn_scrolltop">
            <i className="fas fa-chevron-up"></i>
        </button>
    );
}

export {ScrollTop};