import React from 'react';
import { Navbar } from "../../components/Navbar";
import { Waves } from "../../components/Waves";
import { Footer } from "../../components/Footer";
import { ScrollTop } from "../../components/ScrollTop";
import { ToogleMenu } from "../../components/ToogleMenu";
import { ChatBot } from "../../components/ChatBot";
import { NewsPage } from '../../components/NewsPage';

import publisherImage from "../../imgs/cidelac_logo.jpeg"
import ScrollTopPage from '../../components/ScrollTopPage';

function New02() {

  return (
    <React.Fragment>
      <ScrollTopPage/>
      <Navbar/>
      <NewsPage
        Title= "La importancia de la leche en la dieta humana"
        Subtitle= "Proteína esencial y desafíos actuales"
        Tag1= "Tecnología"
        Tag2= "Nutrición"
        Tag3= "Sostenibilidad"
        PublisherImg={publisherImage}
        PublisherName= "Jesus Martheyn"
        Date= "Apr 2, 2023"
        Description= {`Descubre por qué la leche es vital en una dieta saludable debido a su aporte de proteínas, y conoce los desafíos que enfrentamos debido al bajo consumo actual. 
        Exploraremos los beneficios de la leche, su papel en el desarrollo del cuerpo humano, y las alternativas disponibles para aquellos que no la consumen. 
        Aprende cómo garantizar una ingesta adecuada de proteínas en una dieta equilibrada sin leche.`}
        
        Col1P1 = {`La leche ha sido un alimento fundamental en la dieta humana durante siglos, y su consumo aporta una serie de beneficios para la salud. 
        Además de ser una fuente rica en nutrientes, la leche es especialmente conocida por su aporte de proteínas de alta calidad. Sin embargo, en la actualidad nos enfrentamos a desafíos relacionados con el bajo consumo de leche, lo cual plantea interrogantes sobre cómo garantizar una ingesta adecuada de proteínas en una dieta saludable.`}
        Col1P2={`La proteína es esencial para el crecimiento, desarrollo y mantenimiento del cuerpo humano. La leche contiene una combinación equilibrada de aminoácidos esenciales, los componentes básicos de las proteínas, lo que la convierte en una excelente fuente de proteínas completas. Estas proteínas son de alta calidad y contienen todos los aminoácidos esenciales que el cuerpo necesita para funcionar correctamente.`}
        Col1P3={`Beneficios de consumir leche y proteínas en una dieta saludable:
        1. Construcción y reparación de tejidos: Las proteínas son fundamentales para la formación y reparación de tejidos, incluyendo músculos, huesos, piel y órganos. Una ingesta adecuada de proteínas contribuye a mantener un sistema musculoesquelético saludable.
        2. Saciedad y control del peso: Las proteínas son más saciantes que los carbohidratos o las grasas, lo que significa que nos ayudan a sentirnos satisfechos por más tiempo. Esto puede ser beneficioso para el control del peso, ya que reduce el apetito y evita los antojos frecuentes.
        3. Energía y metabolismo: Las proteínas juegan un papel crucial en la producción de energía y el funcionamiento metabólico. Ayudan en la síntesis de enzimas, hormonas y neurotransmisores, así como en el transporte y almacenamiento de nutrientes.`}
        
        Col2P1={`Desafíos actuales del bajo consumo de leche:
        A pesar de los beneficios que la leche y las proteínas aportan a la salud, enfrentamos desafíos relacionados con el bajo consumo de leche en la actualidad. Algunos de estos desafíos incluyen:`}
        Col2P2={`1. Intolerancia a la lactosa: Muchas personas presentan intolerancia a la lactosa, el azúcar presente en la leche. Esto puede dificultar su consumo y requerir alternativas lácteas o suplementos de lactasa.
        2. Preferencia por alternativas vegetales: El mercado ha experimentado un crecimiento significativo en la oferta de alternativas vegetales a la leche, como la leche de almendra, soja o avena. Algunas personas optan por estas alternativas por razones éticas, medioambientales o de preferencia personal.
        3.Cambios en los patrones de consumo: Los hábitos alimentarios han evolucionado y se han diversificado, y algunas personas han reducido o eliminado el consumo de lácteos de su dieta por diferentes motivos, como la adopción de dietas veganas o vegetarianas.`}
        Col2P3={`En conslusión, a pesar de los desafíos actuales relacionados con el bajo consumo de leche, es importante reconocer la valiosa contribución de este alimento en términos de su aporte de proteínas en una dieta saludable. Aquellos que consumen leche y productos lácteos pueden beneficiarse de su contenido proteico de alta calidad, que es esencial para el crecimiento y mantenimiento del cuerpo humano. Sin embargo, es fundamental adaptar la oferta y educar sobre las alternativas adecuadas para aquellos que no consumen leche debido a intolerancias, preferencias o elecciones dietéticas. La diversificación de opciones puede ayudar a asegurar una ingesta adecuada de proteínas para todos.`}
        
        Col3ImgUrl="https://img.freepik.com/foto-gratis/composicion-leche-pan-ia-generativa_169016-28742.jpg?w=1060&t=st=1684282012~exp=1684282612~hmac=7c38c68d3aa5c43f111f5d07f47bb5d5aa91e6352e2a0ef9a2fe4831ca6070e7"
        Col3ImgAlt= "food based on milk"
      />
      <Waves/>
      <Footer/>
      <ScrollTop/>
      <ToogleMenu/>
      <ChatBot/>
    </React.Fragment>
  )
}

export {New02};
