import Options from "../Options";

const SpanishQuotationsOptions = (props) => {
  const options = [
    {
      name: "Whatsapp con un agente",
      handler: props.actionProvider.handleWhatsappContactSpanish,
      id: 1
    },
    // {
    //   name: "Ingresar a Dora",
    //   handler: props.actionProvider.handleJoinDora,
    //   id: 2
    // },
    // {
    //   name: "Ser contactado por un agente",
    //   handler: props.actionProvider.handleLaterContact,
    //   id: 3
    // },
    // {
    //   name: "Quiero concoer más sobre Dora",
    //   handler: props.actionProvider.handleDoraMoreInfoSpanish,
    //   id: 4
    // },
  ];
  return <Options options={options} title="Algunas aciones Sugeridas para continuar con tu solicitud de cotización:" {...props} />;
};

export default SpanishQuotationsOptions;