import { createChatBotMessage } from "react-chatbot-kit";
import CoBotAvatar from "./../CoBotAvatar";

import LanguageSelector from "./../widgets/LanguageSelector";

import OverviewSpanish from "./../widgets/spanishFlow/OverviewSpanish";
import QuotationSpanish from "./../widgets/spanishFlow/QuotationSpanish";
import WhatsappContactSpanish from "../widgets/spanishFlow/WhatsappContactSpanish";

import OverviewEnglish from "./../widgets/englishFlow/OverviewEnglish";
import QuotationEnglish from "./../widgets/englishFlow/QuotationEnglish";
import WhatsappContactEnglish from "../widgets/englishFlow/WhatsappContactEnglish";

import Overview from "./../widgets/Overview";
import GlobalStatistics from "./../widgets/GlobalStatistics";

const botName = 'CideBot';

const config = {
  initialMessages: [
    createChatBotMessage(`Hi, welcome to ${botName}, I'm here 24/7 to help you with your needs`),
    createChatBotMessage(
      "Please chose your language",
      {
        withAvatar: true,
        delay: 400,
        widget: "languageSelector"
      }
    )
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: 'orange',
    },
    chatButton: {
      backgroundColor: 'green',
    },
  },
  state:{},
  customComponents: {
     header: () => <div style={{ backgroundColor: 'green', padding: "5px", borderRadius: "3px" , color: "white", textAlign: "center"}}>{botName}</div>,
     botAvatar: (props) => <CoBotAvatar {...props} />,
 },
 widgets: [
  {
    widgetName: "languageSelector",
    widgetFunc: (props) => <LanguageSelector {...props} />,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "overviewSpanish",
    widgetFunc: (props) => <OverviewSpanish {...props}/>,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "quotationSpanish",
    widgetFunc: (props) => <QuotationSpanish {...props}/>,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "whatsappContactSpanish",
    widgetFunc: (props) => <WhatsappContactSpanish {...props} />,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "overviewEnglish",
    widgetFunc: (props) => <OverviewEnglish {...props}/>,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "quotationEnglish",
    widgetFunc: (props) => <QuotationEnglish {...props}/>,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "whatsappContactEnglish",
    widgetFunc: (props) => <WhatsappContactEnglish {...props} />,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "overview",
    widgetFunc: (props) => <Overview {...props} />,
    mapStateToProps: ["messages"]
  },
  {
    widgetName: "globalStatistics",
    widgetFunc: (props) => <GlobalStatistics {...props} />,
  },
],
}

export default config;