import React from "react";
import "./styles/App.css";
import "./styles/chatbot.css";

import Home from './pages/Home';
import { OurStrategy} from "./pages/OurStrategy";
import { EmbracingSustainability } from "./pages/EmbracingSustainability";
import { PrivacyStatement } from "./pages/PrivacyStatement";
import { SocialResponsability } from "./pages/SocialResponsability";

import { createHashRouter, RouterProvider } from "react-router-dom";
import { ToogleProvider } from "./hooks/ToogleContext";
import { New01 } from "./pages/noticias/New01";
import { New02 } from "./pages/noticias/New02";
import { New03 } from "./pages/noticias/New03";
import { AboutUs } from "./pages/AboutUs";
import { SaleLocations } from "./pages/SaleLocations";

const router = createHashRouter ([
  {
      path: "/",
      element: <Home/>,
  },
  {
    path: "/aboutUs",
    element: <AboutUs/>,
  },
  {
    path: "/sale-locations",
    element: <SaleLocations/>,
  },
  {
    path: "/strategy",
    element: <OurStrategy/>,
  },
  {
    path: "/sustainability",
    element: <EmbracingSustainability/>,
  },
  {
    path: "/social-responsability",
    element: <SocialResponsability/>,
  },
  {
    path: "/privacy",
    element: <PrivacyStatement/>,
  },
  {
    path: "/news/el-futuro-de-la-industria-lactea-IA",
    element: <New01/>,
  },
  {
    path: "/news/la-importancia-de-la-leche-en-la-dieta-humana",
    element: <New02/>,
  },
  {
    path: "/news/la-produccion-de-leche-en-polvo-y-su-compromiso-con-el-medio-ambiente",
    element: <New03/>,
  },

]);

function App() {

  return (
    <ToogleProvider>
      <RouterProvider router = {router}/>
    </ToogleProvider>
   
  );
}

export default App;
