import React from "react";
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import config from "../chatbot/configs/chatbotConfig";
import MessageParser from "../chatbot/MessageParser";
import ActionProvider from "../chatbot/ActionProvider";
import "../styles/chatbot.css";
import { ToogleContext } from "../hooks/ToogleContext";

function ChatBot (props){

    const {openChatBot, setopenChatBot} = React.useContext(ToogleContext);

    const chatbotButtom = () => {
        setopenChatBot(false);
    };
    console.log(openChatBot);
    return (
        <div>
            <div>
                <button className={`chatbotCloseButtom ${openChatBot ? "chatbotCloseButtom": "chatbotCloseButtom-closed"}`} onClick={ chatbotButtom }> (X) Close</button>
            </div>
            <div className={`react-chatbot-kit-chat-container ${openChatBot ? "react-chatbot-kit-chat-container": "react-chatbot-kit-chat-container-closed"} `} >
                <Chatbot
                    config={config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
            </div>
             
        </div>
       
    );
}

export {ChatBot};