import React from "react";
import { useNavigate } from "react-router-dom";

import { SectionTitle } from "./SectionTitle";
import logo from "./../imgs/cidelac_logo.jpeg";

import "./../styles/newsCard.css";

function NewsCard () {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="news-section-container">
                <SectionTitle
                    BoldText= "Noticias"
                    ligthText= " Cidelac"
                />

                <div class="container">
                    <div class="card">
                        <div class="card-header">
                        <img src="https://cdb.arla.com/api/assets/arla-com/a921bb93-2e0c-4d03-8f90-5eaeb2ed76c4/new-artificial-intelligence-tool-predicts-how-much-milk-15-million-cows-will-produce-2885091.jpg?width=1140&height=575&mode=cropupsize&quality=90" alt="IA in Farms" />
                        </div>
                        <div class="card-body">
                        <span class="tag tag-teal">Tecnología</span>
                        <h4 className="news-card-title" onClick={() => navigate("/news/el-futuro-de-la-industria-lactea-IA")}>
                            El futuro de la industria láctea: Cómo la inteligencia artificial está impulsando el crecimiento del sector
                        </h4>
                        <p>
                        Descubre cómo la inteligencia artificial (IA) está transformando la industria láctea y contribuyendo a su crecimiento. 
                        Adéntrate en el emocionante futuro de la industria láctea impulsada por la IA.
                        </p>
                        <div class="user">
                            <img src={logo} alt="user" />
                            <div class="user-info">
                            <h5>Cidelac</h5>
                            <small>2h ago</small>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                        <img src="https://img.freepik.com/foto-gratis/composicion-leche-pan-ia-generativa_169016-28742.jpg?w=1060&t=st=1684282012~exp=1684282612~hmac=7c38c68d3aa5c43f111f5d07f47bb5d5aa91e6352e2a0ef9a2fe4831ca6070e7" alt="food based on milk" />
                        </div>
                        <div class="card-body">
                        <span class="tag tag-purple">Nutrición</span>
                        <h4 className="news-card-title" onClick={() => navigate("/news/la-importancia-de-la-leche-en-la-dieta-humana")}>
                            La importancia de la leche en la dieta humana: Proteína esencial y desafíos actuales
                        </h4>
                        <p>
                            Descubre por qué la leche es esencial en una dieta saludable y los desafíos que enfrentamos por su bajo consumo. 
                            Exploraremos sus beneficios, su papel en el desarrollo humano y las alternativas disponibles para aquellos que no la consumen. 
                            
                        </p>
                        <div class="user">
                            <img src={logo} alt="user" />
                            <div class="user-info">
                            <h5>Cidelac</h5>
                            <small>Yesterday</small>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                        <img src="https://img.freepik.com/foto-gratis/iot-agricultura-inteligente-fondo-arbol-plantacion-manual_53876-124626.jpg?t=st=1684376168~exp=1684376768~hmac=9ea6fa57b8f0d33cfb14a8c1fe41407a33fc59c5b32b0c101ac642d909f8190d" alt="smart farms" />
                        </div>
                        <div class="card-body">
                        <span class="tag tag-pink">Sostenibilidad</span>
                        <h4 className="news-card-title" onClick={() => navigate("/news/la-produccion-de-leche-en-polvo-y-su-compromiso-con-el-medio-ambiente")}>
                            Avanzando hacia un futuro sostenible: La producción de leche en polvo y su compromiso con el medio ambiente
                        </h4>
                        <p>
                            Descubre cómo la industria láctea está adoptando prácticas sostenibles en la producción de leche en polvo. 
                            Explora temas como eficiencia energética, gestión del agua, agricultura regenerativa y reducción de emisiones de carbono. 
                        </p>
                        <div class="user">
                            <img src={logo} alt="user" />
                            <div class="user-info">
                            <h5>Cidelac</h5>
                            <small>1w ago</small>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export { NewsCard};