class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
  }

  handleSpanishLanguage = (options) => {
    const message = this.createChatBotMessage(
      "Has seleccionado Español como idioma. Perfecto, ¿Cómo te puedo ayudar?",
      {
        widget: "overviewSpanish",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );

    this.addMessageToState(message);
  };

  handleQuotationSpanish = (options) => {
    
    const message = this.createChatBotMessage(
      `Perfecto, lo primero que debes saber sobre la forma en que Cidelac provee una cotización es que esta se realiza através 
      de nuestro sistema llamado Dora. Este es un sistema que nos permitirá conocer su información de compañía, contar con la 
      información necesaria para que uno de nuestros agentes le proporcionen un acompañamiento personalizado y pueda aclarar dudas.`
      ,
      {
        widget: "quotationSpanish",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );

    this.addMessageToState(message);
  };

  handleWhatsappContactSpanish = (options) => {
    const message = this.createChatBotMessage(
     "Estás a punto de dirigirte a chatear con uno de nuestros agentes, haz click en el botón para continuar.",
      {
        widget: "whatsappContactSpanish",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );

    this.addMessageToState(message);
  };

  handleEnglishLanguage = (options) => {
    const message = this.createChatBotMessage(
      "You have chosend English as your Language. Ok, How can I help you today?",
      {
        widget: "overviewEnglish",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );

    this.addMessageToState(message);
  };

  handleQuotationEnglish = (options) => {
    
    const message = this.createChatBotMessage(
      `Perfect, the first thing you may know is that Cidelac provides quotes throught a platform Called Dora only. 
      This is a system that allow us to know your company information, and get the necessary information for prividing you 
      a personalized acompainment and clear any doubt.`
      ,
      {
        widget: "quotationEnglish",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );

    this.addMessageToState(message);
  };

  handleWhatsappContactEnglish = (options) => {
    const message = this.createChatBotMessage(
     "You are about to chat with one of our Agents, click in the buttom to continue.",
      {
        widget: "whatsappContactEnglish",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );

    this.addMessageToState(message);
  };

  handleGlobalStats = () => {
    const message = this.createChatBotMessage(
      "Here's the latest global stats.",
      {
        widget: "globalStatistics",
        loading: true,
        terminateLoading: true,
        withAvatar: true
      }
    );

    this.addMessageToState(message);
  };

  addMessageToState = (message) => {
    this.setState((state) => ({
      ...state,
      messages: [...state.messages, message]
    }));
  };
}

export default ActionProvider;
