import React from "react";
import { SectionTitle } from "../SectionTitle";

import "./../../styles/aboutUsTimeline.scss"

function AboutUsTimeline () {
    return (
        <React.Fragment>
        <SectionTitle
            ligthText= "Nuestra Historia "
            BoldText= "Línea Temporal de  "
        />
        <div class="timeline"> 
            <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                <div class="timeline__event__icon ">
                <i class="lni-cake"></i>

                </div>
                <div class="timeline__event__date">
                20-08-2008
                </div>
                <div class="timeline__event__content ">
                <div class="timeline__event__title">
                    Birthday
                </div>
                <div class="timeline__event__description">
                    <p>Iniciamos operaciones como Surtileche, enfocados en programas Institucionales en Bolívar, Atlantico y Santander.</p>
                </div>
                </div>
            </div>

            <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                <div class="timeline__event__icon">
                <i class="lni-burger"></i>

                </div>
                <div class="timeline__event__date">
                20-01-2009
                </div>
                <div class="timeline__event__content">
                <div class="timeline__event__title">
                    PAE Lunch
                </div>
                <div class="timeline__event__description">
                    <p>Iniciamos nuestra relación con los operadores de programas del PAE de: Cartagena, Dpto de Bolívar, Barranquilla, Sabanagrande, Puerto Colombia, Soledad, Dpto de Atlantico, Barrancabermeja, Tolú, Barbosa y Bucaramanga.</p>
                </div>
                </div>
            </div>

            <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                <div class="timeline__event__icon">
                <i class="lni-slim"></i>

                </div>
                <div class="timeline__event__date">
                10-05-2015
                </div>
                <div class="timeline__event__content">
                <div class="timeline__event__title">
                    Rebranding
                </div>
                <div class="timeline__event__description">
                    <p>Cambiamos nuestra identidad a Cidelac, con el propósito de abrirnos camino al mercado internacional.</p>
                </div>

                </div>
            </div>

            <div class="timeline__event animated fadeInUp timeline__event--type1">
                <div class="timeline__event__icon">
                <i class="lni-cake"></i>

                </div>
                <div class="timeline__event__date">
                20-08-2015
                </div>
                <div class="timeline__event__content">
                <div class="timeline__event__title">
                    Distributors Lunch
                </div>
                <div class="timeline__event__description">
                    <p>Iniciamos el programa de distribuidores a lo largo del territorio Colombiano.</p>
                </div>
                </div>
            </div>

            <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                <div class="timeline__event__icon">
                <i class="lni-burger"></i>

                </div>
                <div class="timeline__event__date">
                15-09-2022
                </div>
                <div class="timeline__event__content">
                <div class="timeline__event__title">
                    INVIMA REGISTER RENEW
                </div>
                <div class="timeline__event__description">
                    <p>Fuimos renovados en nuestro registro sanitario por parte del Invima.</p>
                </div>
                </div>
            </div>

            <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                <div class="timeline__event__icon">
                <i class="lni-slim"></i>

                </div>
                <div class="timeline__event__date">
                10-05-2023
                </div>
                <div class="timeline__event__content">
                <div class="timeline__event__title">
                    Retail Lunch
                </div>
                <div class="timeline__event__description">
                    <p>Lanzamos nuestro programa de mercado retail.</p>
                </div>

                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export {AboutUsTimeline};