import React from "react";
import "./../../styles/mision.css";
import { SectionTitle } from "../SectionTitle";

import "./../../styles/aboutUsTimeline.scss"

function Mision () {
    return (
        <React.Fragment>
            <SectionTitle
                BoldText= "Misión y Visión"
            />

            <div className="mision-vision-main-container">
                <div className="mision-vision-inner-container">
                    <div className="mision-vision-box">
                        <div className="mision-vision-inner-box">
                            <h1 className="mision-vision-titles">Misión</h1>
                            <div className="mision-vision-text-container">
                                <p className="mision-vision-text">
                                    Nuestra misión es ser la empresa líder en la producción y empaquetado de leche en polvo 
                                    en Colombia, dedicados a ofrecer productos lácteos de la más alta calidad para nutrir a las 
                                    familias colombianas. Nos comprometemos a brindar excelencia y valor, asegurando que cada 
                                    producto en la mesa de nuestros consumidores sea una muestra de nuestra dedicación a la 
                                    calidad y al mejor precio.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="mision-vision-box">
                        <div className="mision-vision-inner-box">
                            <h1 className="mision-vision-titles">Visión</h1>
                            <div className="mision-vision-text-container">
                                <p className="mision-vision-text">
                                    Aspiramos a liderar el mercado no solo a través de la excelencia en nuestros productos, 
                                    sino también mediante un firme compromiso con la sostenibilidad y la responsabilidad social.
                                    Operando con prácticas respetuosas con el medio ambiente, cumpliendo con las 
                                    regulaciones y normativas de cada país en el que operamos. Nuestro objetivo es contribuir al 
                                    bienestar de las comunidades a las que servimos, manteniendo altos estándares de calidad, 
                                    ética y transparencia en todas nuestras operaciones.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export {Mision};