import React from "react";

import packAdulto from "./../imgs/empacados_adultos.png";
import packNinos from "./../imgs/empacados_ninos.png";
import packBultos from "./../imgs/empacados_bulto.png";
import { ProductsTableDetailsMobile} from "./ProductsTableDetailsMobile";
import { SectionTitle } from "./SectionTitle";

import "../styles/productsMobile.css";

import { ToogleContext } from "../hooks/ToogleContext";

function ProductsMobile ({productName,productDescription, presentation1, presentation2, presentation3}){
   
    
    const {openChatBot, setopenChatBot} = React.useContext(ToogleContext);

    const chatbotButtom = () => {
        setopenChatBot(true);
    };
    
    return (
        <React.Fragment>
            <SectionTitle
            BoldText= "Portafolio"
            ligthText= " de Productos"
            />
            <div className="mobile-product-details-main-container">
                <img src={packAdulto} className="mobile-product-img"/>
                <ProductsTableDetailsMobile
                    productName = "Leche Entera en Polvo Empacada"
                    productDescription = "Leche Entera en Polvo empacada en bolsa laminada de alta resistencia y capacidad de conservación del producto. Producto de producción nacional bajo las normatividades sanitarias Colombianas. Producto rico en vitaminas A,D, Hierro y Zinc. Ideal para programas de alimentación escolar."
                    presentation1 = "380 grs"
                    presentation2 = "500 grs"
                    presentation3 = "900 grs"
                />
            </div>

            <div className="mobile-product-details-main-container">
                <img src={packNinos} className="mobile-product-img"/>
                <ProductsTableDetailsMobile
                    productName="Leche en Polvo Enriquecida Empacada"
                    productDescription = "Leche en Polvo empacada en bolsa laminada de alta resistencia y capacidad de conservación del producto. Producto de producción nacional bajo las normatividades sanitarias Colombianas. Producto enriquecido con vitaminas A,D, Hierro y Zinc. Ideal para programas de alimentación escolar."
                    presentation1 = "380 grs"
                    presentation2 = "500 grs"
                    presentation3 = "900 grs"
                />
            </div>

            <div className="mobile-product-details-main-container">
                <img src={packBultos} className="mobile-product-img"/>
                <ProductsTableDetailsMobile
                    productName="Leche en Polvo Entera Bulto"
                    productDescription = "Leche Entera en Polvo empacada en bolsa de papel kraft de alta resistencia y capacidad de conservación del producto. Producto de producción nacional bajo las normatividades sanitarias Colombianas. Producto rico en vitaminas A,D, Hierro y Zinc. Ideal para venta al granel y producción de preparaciones lacteas."
                    productPresentations= {["5 kg", "12,5 kg", "25 kg"]}
                    presentation1 = "5 kg"
                    presentation2 = "12,5 kg"
                    presentation3 = "25 kg"
                />
            </div>
        </React.Fragment>
    );
}

export {ProductsMobile};