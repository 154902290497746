import logo from "../imgs/cidelac_logo_white.png";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/footer.css";

function Footer() {

    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="grupo-1">
                <div className="box">
                    <figure>
                        <a onClick={() => navigate("/")}>
                            <img src={logo} alt="logo-cidelac" className="logo-footer" />
                        </a>
                    </figure>
                </div>
                <div className="box">
                    <h2>Our Company</h2>
                    <div className="corporate-links">
                        <ul><a onClick={() => navigate("/aboutUs")} >AboutUs</a></ul>
                        {/* <ul><a onClick={() => navigate("/strategy")} >Our Strategy</a></ul>
                        <ul><a onClick={() => navigate("/sustainability")} >Embracing Sustainability</a></ul>
                        <ul><a onClick={() => navigate("/social-responsability")} >Corporate Social Responsability</a></ul>
                        <ul><a onClick={() => navigate("/privacy")} >Privacy Statement</a></ul> */}
                    </div>
                </div>
                {/* <div className="box">
                    <h2>Careers</h2>
                    <div className="corporate-links">
                        <ul><a href="#">Life at Cidelac</a></ul>
                        <ul><a href="#">Open Positions</a></ul>
                        <ul><a href="#">FAQ</a></ul>
                    </div>
                </div> */}
                <div className="box">
                    <h2>Follow Us</h2>
                    <div className="social-network">
                        <a href="https://twitter.com/cidelac" className="fa fa-twitter" target="_blank"></a>
                        <a href="https://www.instagram.com/cidelac/" className="fa fa-instagram" target="_blank"></a>
                        <a href="https://www.linkedin.com/company/cidelac/" className="fa fa-linkedin" target="_blank"></a>
                    </div>
                </div>
            </div>

            <div className="grupo-2">
                <small>&copy; 2023 <b> CIDELAC S.A.S</b> - All Rights Reserved.</small>
            </div>
        </footer>
    );

}

export {Footer};