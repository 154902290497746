import React from "react";
import "../styles/bannerPages.css";

function BannerPages ({pageName,pageSubtitle, pageDescription}){
    return (
        <header className="bannerPage-container">
            <div className="overlay">
                <h1 className="bannerPage-title">{pageName}</h1>
                <h3 className="bannerPage-subtitle">{pageSubtitle}</h3>
                <p className="bannerPage-description">{pageDescription}</p>
                    <br/>
                    {/* <button className="bannerPage-btn">READ MORE</button> */}
            </div>
        </header>
    );
}

export {BannerPages};