import React from 'react';
import { Navbar } from "../components/Navbar";
import { BannerPages } from "../components/BannerPages";
import { Waves } from "../components/Waves";
import { Footer } from "../components/Footer";
import { ScrollTop } from "../components/ScrollTop";
import { CideBot } from "../components/CideBot";
import { ChatBot } from "../components/ChatBot";
import { AboutUsTimeline } from '../components/AboutUs/AboutUsTimeline';
import { Mision } from '../components/AboutUs/Mision';

function AboutUs() {

  return (
    <React.Fragment>
      <Navbar/>
      <BannerPages/>
      <Mision/>
      <AboutUsTimeline/>
      <Waves/>
      <Footer/>
      <ScrollTop/>
      <CideBot/>
      <ChatBot/>
    </React.Fragment>
  )
}

export {AboutUs};
