import React from "react";
import "../styles/productsTableDetailsMobile.css";

import { ToogleContext } from "../hooks/ToogleContext";

function ProductsTableDetailsMobile ({productName,productDescription, presentation1, presentation2, presentation3}){

    const {openChatBot, setopenChatBot} = React.useContext(ToogleContext);

    const chatbotButtom = () => {
        setopenChatBot(true);
    };
    
    return (
        <section className="mobile-product-details-container">
            <div>
                <h1 className="mobile-product-details-title">{productName}</h1>
            </div>
            <div>
                <h1 className="mobile-product-details-subtitle">Descripción</h1>
                <p className="mobile-product-details-content">{productDescription} </p>
            </div>
            <div className="mobile-product-details-section2">
                <h1 className="mobile-product-details-subtitle">Presentaciones</h1>
                <p className="mobile-product-details-content">Disponible en las presentaciones de:</p>
                <div className="mobile-product-presentation-labels">
                    <label className="mobile-product-label-item">{presentation1}</label>
                    <label className="mobile-product-label-item">{presentation2}</label>
                    <label className="mobile-product-label-item">{presentation3}</label>
                </div>
            </div>
            <button className="mobile-product-detail-btn" onClick={chatbotButtom}>Cotizar</button>
        </section>
    );
}

export {ProductsTableDetailsMobile};