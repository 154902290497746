import React from "react";
import "../styles/languageToogle.css";
import { ToogleContext } from "../hooks/ToogleContext";

function LanguageToogle (){

    const {appLanguage, setappLanguage} = React.useContext(ToogleContext);

    const toggleLanguage = () => {
        setappLanguage(!appLanguage);
    };

    return (
        <>
            <input type="checkbox" id="switch"/>
            <div class="app">
                <div class="body">
                    <div class="phone">
                        <div class="content">
                            <label for="switch" class="label-toogle">
                                <div class="toggle"></div>
                                <div class="names">
                                    <p class="light">Español</p>
                                    <p class="dark">English</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export {LanguageToogle};