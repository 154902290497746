import React from 'react';
import { Navbar } from "../../components/Navbar";
import { Waves } from "../../components/Waves";
import { Footer } from "../../components/Footer";
import { ScrollTop } from "../../components/ScrollTop";
import { ToogleMenu } from "../../components/ToogleMenu";
import { ChatBot } from "../../components/ChatBot";
import { NewsPage } from '../../components/NewsPage';

import publisherImage from "../../imgs/cidelac_logo.jpeg"
import ScrollTopPage from '../../components/ScrollTopPage';

function New03() {

  return (
    <React.Fragment>
      <ScrollTopPage/>
      <Navbar/>
      <NewsPage
        Title= "Avanzando hacia un futuro sostenible"
        Subtitle= "La producción de leche en polvo y su compromiso con el medio ambiente"
        Tag1= "Tecnología"
        Tag2= "Nutrición"
        Tag3= "Sostenibilidad"
        PublisherImg={publisherImage}
        PublisherName= "Jesus Martheyn"
        Date= "Jan 15, 2023"
        Description= {`Descubre cómo la industria láctea está impulsando prácticas sostenibles en la producción de leche en polvo para reducir su impacto ambiental. 
        Este artículo examina iniciativas como la eficiencia energética, la gestión del agua, la agricultura regenerativa y la reducción de emisiones de carbono. 
        Explora también cómo el envasado sostenible y las certificaciones respaldan el compromiso de la industria con un futuro más verde y equilibrado.`}
        
        Col1P1 = {`En la era actual, donde la sostenibilidad se ha convertido en una prioridad global, la industria láctea se encuentra en constante evolución para impulsar prácticas más sostenibles en la producción de leche en polvo. En este artículo, exploraremos las iniciativas y avances que están transformando el sector, con un enfoque en la sostenibilidad y la reducción de su impacto ambiental.`}
        Col1P2={`Eficiencia energética y gestión del agua:
        La producción de leche en polvo está adoptando estrategias para optimizar el uso de recursos naturales. Las instalaciones modernas están implementando tecnologías de eficiencia energética, como sistemas de iluminación LED, recuperación de calor y mejoras en la eficiencia de los equipos. Además, se están implementando prácticas de gestión del agua para reducir el consumo y mejorar la calidad del agua utilizada en los procesos de producción.
        
        Agricultura regenerativa y bienestar animal:
        La sostenibilidad en la producción de leche en polvo también involucra prácticas agrícolas que promueven la regeneración del suelo y la preservación de los ecosistemas. Los productores están adoptando métodos de agricultura regenerativa, como la rotación de cultivos, la siembra directa y el manejo integrado de plagas. Asimismo, se están implementando programas de bienestar animal para garantizar condiciones adecuadas de crianza y cuidado del ganado lechero.`}
        Col1P3={`Reducción de emisiones y huella de carbono:
        La industria láctea está trabajando activamente en la reducción de las emisiones de gases de efecto invernadero. Se están implementando medidas para optimizar la eficiencia energética de los procesos de producción y se están buscando fuentes de energía renovable. Además, se están evaluando las opciones de captura y almacenamiento de carbono, así como la implementación de prácticas de gestión de estiércol y residuos para minimizar la huella de carbono.`}
        
        Col2P1={`Envasado sostenible y reciclaje:
        El compromiso con la sostenibilidad también se extiende al empaquetado de la leche en polvo. Las empresas están adoptando envases ecológicos, como materiales biodegradables o reciclables. Además, se están promoviendo programas de reciclaje y se fomenta la concienciación del consumidor sobre la importancia de la separación y el reciclaje adecuado de los envases.`}
        Col2P2={`Certificaciones y estándares de sostenibilidad:
        La producción de leche en polvo sostenible se respalda con certificaciones y estándares reconocidos a nivel internacional. Algunas de las certificaciones más relevantes incluyen los estándares del Global Dairy Trade, el Programa de Agricultura Sostenible (Sustainable Agriculture Initiative) y las certificaciones relacionadas con la trazabilidad y calidad de los productos lácteos.`}
        Col2P3={`En conclusión, la producción de leche en polvo está experimentando una transformación sostenible, con un enfoque en la eficiencia energética, la gestión responsable del agua, la agricultura regenerativa, la reducción de emisiones y la adopción de envasados sostenibles. Las empresas del sector están comprometidas en su contribución a un futuro más sostenible y en la promoción de prácticas responsables en toda la cadena de suministro. Al elegir productos de leche en polvo con un enfoque sostenible, los consumidores también pueden participar activamente en la construcción de un mundo más verde y equilibrado.`}
        
        Col3ImgUrl="https://img.freepik.com/foto-gratis/iot-agricultura-inteligente-fondo-arbol-plantacion-manual_53876-124626.jpg?t=st=1684376168~exp=1684376768~hmac=9ea6fa57b8f0d33cfb14a8c1fe41407a33fc59c5b32b0c101ac642d909f8190d"
        Col3ImgAlt= "smart farms"
      />
      <Waves/>
      <Footer/>
      <ScrollTop/>
      <ToogleMenu/>
      <ChatBot/>
    </React.Fragment>
  )
}

export {New03};
