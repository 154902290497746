import Options from "./Options";

const LanguagueOptions = (props) => {
  const options = [
    {
      name: "English",
      handler: props.actionProvider.handleEnglishLanguage,
      id: 1
    },
    {
      name: "Español",
      handler: props.actionProvider.handleSpanishLanguage,
      id: 2
    },
  ];
  return <Options options={options} title="Suggested Options" {...props} />;
};

export default LanguagueOptions;