import Options from "./Options";

const GeneralOptions = (props) => {
  const options = [
    {
      name: "English",
      handler: props.actionProvider.handleGlobalStats,
      id: 1
    },
    {
      name: "Español",
      handler: props.actionProvider.handleHello,
      id: 2
    },
  ];
  return <Options options={options} title="Suggested Options" {...props} />;
};

export default GeneralOptions;