import React from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import styles from './../styles/productsParallax.css';
import packAdulto from "./../imgs/empacados_adultos.png";
import packNinos from "./../imgs/empacados_ninos.png";
import packBultos from "./../imgs/empacados_bulto.png";

import { ProductsTableDetails } from "./ProductsTableDetails";
import { SectionTitle } from './SectionTitle';

export default function ProductsParallax() {

  const alignCenter = { display: 'flex', alignItems: 'right' }

  return (
    <section className='container-parallax'>
      <div className='parallax-menu-section-title '>
        <SectionTitle
          BoldText= "Portafolio"
          ligthText= " de Productos"
        />
      </div>
      
      <Parallax pages={3} horizontal="true" factor={1} className='main-product-parallax-body'>

        <ParallaxLayer sticky={{ start: 0, end: 0.09 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.sticky}`}>
            <img src={packAdulto} className='parallax-img'/>
          </div>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 0.5, end: 0.4 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.sticky}`}>
            <ProductsTableDetails 
              productName = "Leche Entera en Polvo Empacada"
              productDescription = "Leche Entera en Polvo empacada en bolsa laminada de alta resistencia y capacidad de conservación del producto. Producto de producción nacional bajo las normatividades sanitarias Colombianas. Producto rico en vitaminas A,D, Hierro y Zinc. Ideal para programas de alimentación escolar."
              presentation1 = "380 grs"
              presentation2 = "500 grs"
              presentation3 = "900 grs"
            />
          </div>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 1, end: 1.09 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.parallax} `}>
            <img src={packNinos} className='parallax-img'/>
          </div>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 1.5, end: 1.4 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.sticky}`}>
            <ProductsTableDetails 
              productName="Leche en Polvo Enriquecida Empacada"
              productDescription = "Leche en Polvo empacada en bolsa laminada de alta resistencia y capacidad de conservación del producto. Producto de producción nacional bajo las normatividades sanitarias Colombianas. Producto enriquecido con vitaminas A,D, Hierro y Zinc. Ideal para programas de alimentación escolar."
              presentation1 = "380 grs"
              presentation2 = "500 grs"
              presentation3 = "900 grs"
            />
          </div>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 2, end: 2.09 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.sticky}`}>
            <img src={packBultos} className='parallax-img'/>
          </div>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 2.5, end: 2.4 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.sticky}`}>
            <ProductsTableDetails 
              productName="Leche en Polvo Entera Bulto"
              productDescription = "Leche Entera en Polvo empacada en bolsa de papel kraft de alta resistencia y capacidad de conservación del producto. Producto de producción nacional bajo las normatividades sanitarias Colombianas. Producto rico en vitaminas A,D, Hierro y Zinc. Ideal para venta al granel y producción de preparaciones lacteas."
              productPresentations= {["5 kg", "12,5 kg", "25 kg"]}
              presentation1 = "5 kg"
              presentation2 = "12,5 kg"
              presentation3 = "25 kg"
            />
          </div>
        </ParallaxLayer>

        {/* <ParallaxLayer offset={0.5} speed={0.9} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
          <div className={`${styles.cardPar} ${styles.parallax} ${styles.blue}`}>
            <p>Neither am I</p>
          </div>
        </ParallaxLayer> */}

        {/* <ParallaxLayer sticky={{ start: 3, end: 3.09 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.sticky}`}>
            <img src={packAdulto} className='parallax-img'/>
          </div>
        </ParallaxLayer>

        <ParallaxLayer sticky={{ start: 3.5, end: 3.4 }} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <div className={`${styles.cardPar} ${styles.sticky}`}>
            <ProductsTableDetails/>
          </div>
        </ParallaxLayer> */}

        {/* <ParallaxLayer offset={0.5} speed={0.9} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
          <p className={styles.scrollText}>Scroll right</p>
        </ParallaxLayer> */}

        {/* <ParallaxLayer offset={0.5} speed={1} style={{ ...alignCenter, justifyContent: 'flex-end' }}>
          <div className={`${styles.cardPar} ${styles.parallax} ${styles.purple}`}>
            <p>I'm not</p>
          </div>
        </ParallaxLayer> */}

      </Parallax>
    </section>
  )
}
