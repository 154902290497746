import React from "react";
import "../styles/banner.css";
import { ToogleContext } from "../hooks/ToogleContext";

import MessageParser  from "./../chatbot/MessageParser";

function Banner (){

    const {openChatBot, setopenChatBot, openToogleMenu, setopenToogleMenu,} = React.useContext(ToogleContext);

    const chatbotButtom = () => {
        if (setopenChatBot) {setopenChatBot(true)};
        if (openToogleMenu) {setopenToogleMenu(false)}
    };

    const quoteButtom = () => {
        if (setopenChatBot) {setopenChatBot(true)};
        const Quote = new MessageParser("quote");
        Quote.actionProvider="quote";
        
        if (openToogleMenu) {setopenToogleMenu(false)}
    };

    return (
        <div className="banner">
            <div className="wrap">
                <div className="box">
                    <span> Bienvenidos a</span>
                    <h1 className="companyName">CIDELAC</h1>
                    <p>Comprometidos con la producción de productos lacteos para la nutrición de los Colombianos, colocando en sus mesas productos de la mejor calidad y precio. </p>
                    <div className="botones">
                        <a className="btn1" onClick={chatbotButtom}>CideBot</a>
                        <a className="btn2" onClick={quoteButtom}>Cotizar</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {Banner};