import Options from "../Options";

const EnglishOverviewOptions = (props) => {
  const options = [
    {
      name: "Quotation",
      handler: props.actionProvider.handleQuotationEnglish,
      id: 1
    },
    // {
    //   name: "Contact Information",
    //   handler: props.actionProvider.handleContactInfo,
    //   id: 2
    // },
    // {
    //   name: "Products Information",
    //   handler: props.actionProvider.handleProductInfoEnglish,
    //   id: 3
    // },
    {
      name: "WhatsApp Chat",
      handler: props.actionProvider.handleWhatsappContactEnglish,
      id: 4
    },
  ];
  return <Options options={options} title="Suggested Actions:" {...props} />;
};

export default EnglishOverviewOptions;