import Options from "./../Options";

const SpanishOverviewOptions = (props) => {
  const options = [
    {
      name: "Cotizar",
      handler: props.actionProvider.handleQuotationSpanish,
      id: 1
    },
    {
      name: "Información de Contacto",
      handler: props.actionProvider.handleContactInfo,
      id: 2
    },
    {
      name: "Información de Productos",
      handler: props.actionProvider.handleProductInfoSpanish,
      id: 3
    },
    {
      name: "Chat por WhatsApp",
      handler: props.actionProvider.handleWhatsappContactSpanish,
      id: 4
    },
  ];
  return <Options options={options} title="Opciones Sugeridas:" {...props} />;
};

export default SpanishOverviewOptions;