import React from "react";
import { useNavigate } from "react-router-dom";

import leftRow from "./../imgs/arrow-left-solid.svg";
import "./../styles/newsPage.css"

function NewsPage ({Title,Subtitle,Tag1, Tag2, Tag3, PublisherImg, PublisherName, Date, Description, Col1P1, Col1P2, Col1P3, Col2P1, Col2P2, Col2P3,Col3ImgUrl, Col3ImgAlt}) {
    
    const navigate = useNavigate();

    return(
        <section className="news-page-main-container">
            <div className="news-page-navigation">
                <img src={leftRow} className="news-page-back-icon" alt="left row icon"/>
                <button className="news-page-btn1" onClick={() => navigate("/")}>Back</button>
            </div>
            <div className="news-page-header">
                <h1 className="news-page-title">{Title}</h1>
                <h2 className="news-page-subtitle">{Subtitle}</h2>
            </div>
            <div className="news-page-tags">
                <span class="news-tag tag-teal">{Tag1}</span>
                <span class="news-tag tag-purple">{Tag2}</span>
                <span class="news-tag tag-pink">{Tag3}</span>
            </div>
            <div className="new-publish-info">
                <div className="news-publisher-info">
                    <div className="news-publisher-profile">
                        <img src= {PublisherImg} alt="news publisher image" className="news-publisher-image"/>
                        <h3 className="news-publisher-name"> by {PublisherName}</h3>
                    </div>
                    <h3 className="news-publish-date">{Date}</h3>
                </div>
            </div>
            <div className="news-page-resume">
                <p>
                    {Description}
                </p>
            </div>
            <div className="news-page-content-container">
                <div className="news-content-colum-1">
                    <p>
                        {Col1P1}
                    </p>
                    <br/>
                    <p>
                        {Col1P2}
                    </p>
                    <br/>
                    <p>
                        {Col1P3}
                    </p>
                </div>
                <div className="news-content-colum-2">
                    <p>
                        {Col2P1}
                    </p>
                    <br/>
                    <p>
                        {Col2P2}
                    </p>
                    <br/>
                    <p>
                        {Col2P3}
                    </p>
                </div>
                <div className="news-content-colum-3">
                    <img src={Col3ImgUrl} alt={Col3ImgUrl} className="news-content-colum-3-img" />
                </div>
            </div>
        </section>
    );
}

export {NewsPage}