import React from "react";
import "./../styles/servicesMenu.css"

import GranelImg from "./../imgs/GranelCard.png";
import EmpacadoImg from "./../imgs/EmpacadoCard.png";
import RecetasImg from "./../imgs/RecetasCard.jpg";
import ComunidadImg from "./../imgs/Community.jpg";
import DoraImg from "./../imgs/DoraLogo.png";

import logo from "./../imgs/cidelac_logo.jpeg"
import PAE from "./../imgs/logoPAE.svg";

import { SectionTitle } from "./SectionTitle";
import { ServicesCard } from "./ServicesCard";

function ServicesMenu () {
    return (
        <React.Fragment>
            <div className="services-menu-section-title">
                <SectionTitle
                    BoldText = " Catálogo"
                    ligthText = " de Servicios "
                />
            </div>
            
            <div className="services-menu-container">
                <ServicesCard
                    logo = {PAE}
                    // ligthText = "Programa"
                    // boldText= "PAE"
                />

                <ServicesCard
                    logo = {logo}
                    ligthText = "Mercado"
                    boldText= "Retail"
                />

                <ServicesCard
                    logo = {logo}
                    ligthText = "Programa"
                    boldText= "Distribuidor"
                />

                <ServicesCard
                    logo = {logo}
                    ligthText = "Comercio"
                    boldText= "Internacional"
                />
                
            </div>
            

        </React.Fragment>
    );
}

export {ServicesMenu};