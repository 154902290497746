import Options from "../Options";

const SpanishQuotationsOptions = (props) => {
  const options = [
    {
      name: "Whatsapp with Agent",
      handler: props.actionProvider.handleWhatsappContactEnglish,
      id: 1
    },
    // {
    //   name: "Join Dora",
    //   handler: props.actionProvider.handleJoinDora,
    //   id: 2
    // },
    // {
    //   name: "Being Contacted by Agent",
    //   handler: props.actionProvider.handleLaterContact,
    //   id: 3
    // },
    // {
    //   name: "More Infor About Dora",
    //   handler: props.actionProvider.handleDoraMoreInfoEnglish,
    //   id: 4
    // },
  ];
  return <Options options={options} title="Some Actions suggested to continue your Quotation:" {...props} />;
};

export default SpanishQuotationsOptions;