import React from 'react';
// import { useLocalStorage } from './useLocalStorage';

const ToogleContext = React.createContext();

function ToogleProvider(props) {

// const { languague: defaultLanguage} = useLocalStorage('APP_VARS_V1', []);

const [appLanguage, setappLanguage] = React.useState("Español");
const [openChatBot, setopenChatBot] = React.useState(true);
const [openHamburguer, setopenHamburguer] = React.useState(false);
const [openToogleMenu, setopenToogleMenu] = React.useState(false);
const [openQuoteModal, setopenQuoteModal] = React.useState(false);
const [scrollTop, setscrollTop] = React.useState(false);

return (
    <ToogleContext.Provider value={{
      appLanguage,
      setappLanguage,
      openChatBot,
      setopenChatBot,
      openHamburguer,
      setopenHamburguer,
      openToogleMenu,
      setopenToogleMenu,
      openQuoteModal,
      setopenQuoteModal,
      scrollTop,
      setscrollTop,
    }}>
      {props.children}
    </ToogleContext.Provider>
  );

  }

export { ToogleContext, ToogleProvider };