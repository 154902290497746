import React from "react";
import "../styles/bannerPages.css";
import "../styles/productsTableDetails.css";

import { ToogleContext } from "../hooks/ToogleContext";

function ProductsTableDetails ({productName,productDescription,productPresentations, presentation1, presentation2, presentation3}){

    const {openChatBot, setopenChatBot} = React.useContext(ToogleContext);

    const chatbotButtom = () => {
        setopenChatBot(true);
    };

    let presentations = productPresentations;
    let presentationItems = "";

    if (presentations){
        for (let i = 0; i<presentations.length;i++){
            presentationItems = presentationItems + `<label className="product-label-item">` + presentations[i]+ `</label>`;
            console.log(presentationItems)
        }
    }
    
    return (
        <section className="product-details-container">
            <div>
                <h1 className="product-details-title">{productName}</h1>
            </div>
            <div>
                <h1 className="product-details-subtitle">Descripción</h1>
                <p className="product-details-content">{productDescription} </p>
            </div>
            <div className="product-details-section2">
                <h1 className="product-details-subtitle">Presentaciones</h1>
                <p className="product-details-content">Disponible en las presentaciones de:</p>
                <div className="product-presentation-labels">
                    <label className="product-label-item">{presentation1}</label>
                    <label className="product-label-item">{presentation2}</label>
                    <label className="product-label-item">{presentation3}</label>
                </div>
            </div>
            <button className="parallax-product-detail-btn" onClick={chatbotButtom}>Cotizar</button>
        </section>
    );
}

export {ProductsTableDetails};