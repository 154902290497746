import React from "react";
import { Navbar } from "./../components/Navbar";
import { Banner } from "./../components/Banner";
import { ServicesMenu } from "./../components/ServicesMenu";
import { Waves } from "./../components/Waves";
import { Footer } from "./../components/Footer";
import { ScrollTop } from "./../components/ScrollTop";
import { ToogleMenu } from "./../components/ToogleMenu";
import { CideBot } from "../components/CideBot";
import { ChatBot } from "./../components/ChatBot";
import { LanguageToogle } from "./../components/LanguageToogle";
import ProductsView from "../components/ProductsView";
import { AboutUs } from "../components/AboutUs/AboutUs";
import { NewsCard } from "../components/NewsCard";

// import "./../styles/App.css";
// import "./../styles/chatbot.css";

function Home() {

  return (
    <React.Fragment>
      <Navbar/>
      <Banner/>
      <ServicesMenu/>
      {/* <AboutUs/> */}
      <ProductsView/>
      <NewsCard/>
      <Waves/>
      <Footer/>
      <ScrollTop/>
      <CideBot/>
      <ChatBot/>
    </React.Fragment>
  );
}

export default Home;
