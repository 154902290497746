import React from 'react';
import { Navbar } from "../components/Navbar";
import { BannerPages } from "../components/BannerPages";
import { Waves } from "../components/Waves";
import { Footer } from "../components/Footer";
import { ScrollTop } from "../components/ScrollTop";
import { ToogleMenu } from "../components/ToogleMenu";
import { ChatBot } from "../components/ChatBot";

function SocialResponsability() {
  return (
    <React.Fragment>
      <Navbar/>
      <BannerPages
        pageName = "Corporate Social Responsability"
        pageSubtitle = "Reasons for Choosing US"
        pageDescription= "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero nostrum quis, odio veniam itaque ullam debitis qui magnam consequatur ab. Vero nostrum quis, odio veniam itaque ullam debitis qui magnam consequatur ab."
      />
      <Waves/>
      <Footer/>
      <ScrollTop/>
      <ToogleMenu/>
      <ChatBot/>
    </React.Fragment>
  )
}

export {SocialResponsability};
