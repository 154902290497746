import React from "react";
import "./../styles/servicesCard.css"

function ServicesCard ({logo,ligthText, boldText}) {
    return (
        <React.Fragment>
            <section className="services-card-container">
                <img src= {logo} className="services-card-img"/>
                <p className="services-card-ligth-text">{ligthText}</p>
                <h1 className="services-card-bold-text">{boldText}</h1>
            </section>
        </React.Fragment>
    );
}

export {ServicesCard};