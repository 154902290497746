class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  
    parse(message) {

      console.log("entramos al parser");
      message = message.toLowerCase();
      console.log(message);
      if (
        message.includes("spanish") ||
        message.includes("español") ||
        message.includes("castellano")||
        message.includes("Español")
      ) {
        return this.actionProvider.handleSpanishLanguage({ withAvatar: true });
      }

      if (
        message.includes("cotización") ||
        message.includes("cotizacion")
      ) {
        return this.actionProvider.handleQuotationSpanish({ withAvatar: true });
      }

      if (
        message.includes("english") ||
        message.includes("ingles") ||
        message.includes("inglés")
      ) {
        return this.actionProvider.handleEnglishLanguage({ withAvatar: true });
      }

      if (
        message.includes("quote") ||
        message.includes("quotation") ||
        message.includes("estimate")
      ) {
        console.log("entramos al if")
        console.log(this.actionProvider)
        return this.actionProvider.handleQuotationEnglish({ withAvatar: true });
      }
  
      // if (
      //   message.includes("stats") ||
      //   message.includes("statistics") ||
      //   message.includes("deaths")
      // ) {
      //   return [
      //     this.actionProvider.handleGlobalStats(),
      //     this.actionProvider.handleLocalStats()
      //   ];
      // }
  
      if (message.includes("thanks") || message.includes("thank you")) {
        return this.actionProvider.handleThanks();
      }
  
      return this.actionProvider.handleOptions({ withAvatar: true });
    }
  }
  
  export default MessageParser;