import { faHamburger } from "@fortawesome/free-solid-svg-icons";
import { LanguageToogle } from "./LanguageToogle";
import React from "react";
import logo from "../imgs/cidelac_logo.jpeg";
import wappIcon from "../imgs/whatsapp.svg"
import hambMenu from "./../imgs/icon_menu.svg";
import "../styles/navbar.css";
import { useNavigate } from "react-router-dom";
import { ToogleContext } from "../hooks/ToogleContext";

function Navbar (){

    const {openHamburguer, setopenHamburguer} = React.useContext(ToogleContext);

    const openHamburger = () => {
        setopenHamburguer(!openHamburguer);
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    const navigate = useNavigate();

    return (
        <header className="navbar">
            <div className={`sidenav ${openHamburguer ? "sidenavOpen" : "otherclass"}`}>
                <a className="item" onClick ={() => navigate("/")} >Home</a>
                {/* <a className="item" href="#">Productos</a>
                <a className="item" href="#">Recetas</a> */}
                <a className="item" onClick ={() => navigate("/aboutUs")}>Sobre Nosotros</a>
                <a className="item" onClick ={() => navigate("/sale-locations")}>Dónde Comprar</a>
                <div className="language-toogle-btn">
                    <LanguageToogle/>
                </div>
            </div>
            <button className={`hamb-menu ${openHamburguer && "opened"} `} onClick={openHamburger}>  
                <svg width="100" height="100" viewBox="0 0 100 100">
                    <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                    <path class="line line2" d="M 20,50 H 80" />
                    <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                </svg> 
            </button>
            <div className="logo">
                <img onClick={() => navigate("/")} src= {logo}/>
            </div>
            <nav>
                <ul>
                    <li><a onClick={() => navigate("/")}>Home</a></li>
                    {/* <li><a href="#">Productos</a></li>
                    <li><a href="#">Recetas</a></li> */}
                    <li><a onClick ={() => navigate("/aboutUs")}>Sobre Nosotros</a></li>
                    <li><a onClick ={() => navigate("/sale-locations")}>Dónde Comprar</a></li>
                </ul>
            </nav>
            <div >
                <img className="logo-wapp" onClick={() => openInNewTab('https://wa.me/+573157546407?text=Hola, he sido redirijido desde el website de Cidelac y quiero chatear con un Agente', '_blank')} src= {wappIcon}/>
            </div>

            <button className="regular-button" onClick={() => openInNewTab('https://wa.me/+573157546407?text=Hola, he sido redirijido desde el website de Cidelac y quiero chatear con un Agente', '_blank')} type="button" >Whatsapp</button>
        </header>
    );
}

export {Navbar};