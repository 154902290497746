import React from 'react';
import { Navbar } from "../components/Navbar";
import { BannerPages } from "../components/BannerPages";
import { LocationFilter } from '../components/Locations/LocationFilter';
import { Waves } from "../components/Waves";
import { Footer } from "../components/Footer";
import { ScrollTop } from "../components/ScrollTop";
import { CideBot } from "../components/CideBot";
import { ChatBot } from "../components/ChatBot";


function SaleLocations() {

  return (
    <React.Fragment>
      <Navbar/>
      <BannerPages/>
      <LocationFilter/>
      <Waves/>
      <Footer/>
      <ScrollTop/>
      <CideBot/>
      <ChatBot/>
    </React.Fragment>
  )
}

export {SaleLocations};
